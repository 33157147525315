<button mat-icon-button (click)="showNav = !showNav">
  <mat-icon>menu</mat-icon>
</button>
@if (showNav) {
  <mat-nav-list style="width: 250px">
    <a mat-list-item routerLink="/">Dashboard</a>
    <a mat-list-item *ngIf="showPartners" routerLink="/partner">Partners</a>
    <a mat-list-item *ngIf="showPartners" routerLink="/partner-functions">Partner Functions</a>
    <a mat-list-item *ngIf="showPartners" routerLink="/integration-log">Integration Log</a>
    <a mat-list-item *ngIf="showPartners" routerLink="/transactions">Transaction Log</a>
  </mat-nav-list>
}

<mat-toolbar>
  <span routerLink="/">WatchTower</span>

  <span class="flex-grow"></span>

  <small>{{ auth.user.email }}</small>

  <button mat-icon-button (click)="auth.logout()">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>
